document.addEventListener('DOMContentLoaded', () => {

    // SHOW MSG
    var toastTriggerMsg = document.querySelector('.inputToastResult')
    var toastLiveExampleMsg = document.querySelector('.liveToastMsg')
    if (toastTriggerMsg) {
        toastTriggerMsg.addEventListener('click', function () {
            var toast = new bootstrap.Toast(toastLiveExampleMsg)
            toast.show()
        })
    }


});